import axios, { AxiosRequestConfig } from "axios";
import React from "react";
import { UserResponse } from "./models/UserResponse";

const useRestApiV2Service = () => {
    const baseUrl = process.env.REACT_APP_REST_API_PHP_URL!;
    const instance = React.useMemo(
        () =>
            axios.create({
                withCredentials: true,
                baseURL: baseUrl,
                headers: {
                    Accept: '*/*'
                }
            }),
        [baseUrl]
    );

    const loginWithApiKey = async (tenat: string, apiKey: string) => {
        var params = new URLSearchParams();
        params.append('type', 'cookie');
        params.append('expiration', '604800');
        params.append('tenant', tenat);

        const request: AxiosRequestConfig = {
            headers: {
                Authorization: `ApiKey ${tenat}\\${apiKey}`
            },
            params: params,
        };
        const response = await instance.get<{ expiration: string }>(`/user/token`, request);
        return response?.data;
    }

    const getUserInfo = async () => {
        const response = await instance.get<UserResponse>(`/user`);
        return response?.data;
    }

    return { loginWithApiKey, getUserInfo };
}

export default useRestApiV2Service;