import './Intervention.css';
import spinner from '../../../assets/spinner.svg';
import SlideInOut, { SlideInOutChild } from '../SlideInOut/SlideInOut';
import { WorkflowStateContext } from '../../../providers/context/WorkflowStateContext';
import React from 'react';

const Intervention = () => {
    const {
        getFirstSpeakingParticipant,
        getFirstQueuedParticipant,
        getParticipantGroup
    } = React.useContext(WorkflowStateContext);

    const buildFirstSpeakingParticipantLayout = () => {
        const participant = getFirstSpeakingParticipant();
        if (!participant) return;

        const participantGroup = getParticipantGroup(participant.groupId);

        const content: SlideInOutChild = {
            id: `Intervention-speaking${participant?.id}`,
            content: (
                <>
                    <div className="speaking-participant-img-wrapper">
                        <img
                            className='spinner'
                            src={spinner}
                            alt='spinner'>
                        </img>
                        <img
                            className='avatar'
                            src={participant.image}
                            alt={participant.firstName} >
                        </img>
                    </div>
                    <div className="speaking-participant-name-wrapper">
                        <h3 className='name'>
                            {`${participant.firstName} ${participant.lastName}`}
                        </h3>
                        <span className='group'>
                            {participantGroup?.name}
                        </span>
                    </div>
                </>
            )
        };

        return content;
    }

    const buildFirstQueuedParticipantLayout = () => {
        const participant = getFirstQueuedParticipant();

        if (!participant) return;

        const content: SlideInOutChild = {
            id: `Intervention-queued${participant?.id}`,
            content: <div
                className="enqueued-img-wrapper">
                <img src={participant.image}
                    alt={participant.firstName} >
                </img>
            </div>
        };

        return content;
    }

    return (
        <div className='intervention'>
            <div className='speaking-participant-container'>
                <SlideInOut >
                    {buildFirstSpeakingParticipantLayout()}
                </SlideInOut>
            </div>
            <div style={{ width: 'var(--enqueued-img-size)' }}>
                <SlideInOut >
                    {buildFirstQueuedParticipantLayout()}
                </SlideInOut>
            </div>
        </div>
    );

}

export default Intervention;