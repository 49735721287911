import React from 'react';
import { WorkflowStateContext } from '../../../providers/context/WorkflowStateContext';
import './AgendaItem.css';
import ScrollingText from '../ScrollingText/ScrollingText';
import spinner from '../../../assets/spinner.svg';


const AgendaItem = () => {
    const { getRunningAgendaItem } = React.useContext(WorkflowStateContext);

    const runningAgendaItem = getRunningAgendaItem();

    if (!runningAgendaItem) return <></>;
    const items = (runningAgendaItem?.items?.length ?? 0) === 0 ? [runningAgendaItem] : runningAgendaItem!.items.slice().reverse();;
    return (
        <div className='agenda-item-container'>
            <ul className='agenda-item-bubbles-container'>
                {items.map((item) =>
                    <div className='agenda-item-bubble-wrapper' key={item.id}>
                        <img
                            className='spinner'
                            src={spinner}
                            alt='spinner'>
                        </img>
                        <li className='agenda-item-bubble'>
                            <h2>
                                {item.id}
                            </h2>
                        </li>
                    </div>
                )}
            </ul>
            <div className='agenda-item-title-container'>
                <ScrollingText direction='horizontal' initialDelay={2500} element={<h2 className='agenda-item-description' >{runningAgendaItem?.title}</h2>} />
            </div>
        </div>
    );
}

export default AgendaItem;