import { useEffect, useRef } from 'react';

import './ScrollingText.css';

interface ScrollingTextProps {
    element: JSX.Element;
    direction: 'vertical' | 'horizontal';
    speed?: number;
    initialDelay?: number;
}

const ScrollingText = ({ element, direction, speed = 100, initialDelay = 0 }: ScrollingTextProps) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const contentRef = useRef<HTMLDivElement>(null);
    let firstAnimation = true;

    useEffect(() => {
        const timeout = setTimeout(() => {
            animate();
        }, initialDelay);

        return () => {
            if (timeout) clearTimeout(timeout);
        };
    }, []);

    const getOffset = () => {
        const containerWidth = containerRef.current?.offsetWidth ?? 0;
        const contentWidth = contentRef.current?.offsetWidth ?? 0;
        const containerHeight = containerRef.current?.offsetHeight ?? 0;
        const contentHeight = contentRef.current?.offsetHeight ?? 0;

        const offset = direction === 'horizontal' ? contentWidth - containerWidth : contentHeight - containerHeight;

        let animationLength = direction === 'horizontal' ? contentWidth : contentHeight;
        if (!firstAnimation)
            animationLength += direction === 'horizontal' ? containerWidth : containerHeight;

        return { offset, animationLength };
    }

    const animate = () => {
        const { offset, animationLength } = getOffset();
        if (offset > 0) {
            const animationDuration = animationLength / speed;
            contentRef.current?.classList.add('scrolling-element');
            contentRef.current?.style.setProperty('--animation-duration', `${animationDuration}s`);
            contentRef.current?.style.setProperty('--animation-length', `-${animationLength}px`);
        }
    }

    const handleAnimationEnd = () => {
        const { offset } = getOffset();
        firstAnimation = false;

        if (offset > 0) {
            contentRef.current?.classList.add(direction === 'horizontal' ? 'from-right' : 'from-bottom');
            setTimeout(() => {
                requestAnimationFrame(() => {
                    contentRef.current?.classList.remove('scrolling-element');
                    animate();
                });
            }, 0)
        }
    };

    return (
        <div className={`scrolling-container`} ref={containerRef}>
            <div
                className={`${direction}`}
                ref={contentRef}
                onAnimationEnd={handleAnimationEnd}
            >
                {element}
            </div>
        </div>
    );
};

export default ScrollingText;
