import './VotersAvatars.css';
import { Participant } from '../../../model/WorkflowState';
import React, { useState, useEffect } from 'react';
import SlideInOut, { SlideInOutChild } from '../SlideInOut/SlideInOut';

interface VoterAvatarProps {
    voter: Participant;
    borderColor: string;
}

const VoterAvatar = ({ voter, borderColor }: VoterAvatarProps) => {
    return (
        <div className='voter-container'>
            <div
                className="voter-img-wrapper"
                style={{
                    borderColor: borderColor ?? 'white',
                }}
            >
                <img
                    className='avatar'
                    src={voter.image}
                    alt={`${voter.firstName} ${voter.lastName}`}
                />
            </div>
            <div className="voter-name-wrapper">
                <h3 className='name'>
                    {`${voter.firstName} ${voter.lastName}`}
                </h3>
            </div>
        </div>
    );
};

interface VotersAvatarsProps {
    voters: Participant[];
    borderColor: string;
}

const VotersAvatars = ({ voters, borderColor }: VotersAvatarsProps) => {
    const [index, setIndex] = useState(0);
    const elementToShow = 6;

    useEffect(() => {
        const maxIndex = Math.ceil(voters.length / elementToShow);
        const timeout = setTimeout(() => {
            setIndex((prev) => (prev + 1) % maxIndex);
        }, 10000);
        return () => clearTimeout(timeout);
    }, [voters, index]);

    const buildContent = (page: number): SlideInOutChild | undefined => {
        const startIndex = page * elementToShow;
        const endIndex = startIndex + elementToShow;
        const content = voters.slice(startIndex, endIndex).map((voter, idx) => (
            <VoterAvatar
                key={startIndex + idx}
                voter={voter}
                borderColor={borderColor}
            />
        ));
        if (content.length === 0) return;
        return {
            id: page.toString(),
            content: <>{content}</>,
        };
    };

    const content = buildContent(index);

    return (
        <div className='voters-container'>
            {content && <SlideInOut>{content}</SlideInOut>}
        </div>
    );
};

export default VotersAvatars;
