import { createContext } from "react";
import { UserResponse } from "../../services/models/UserResponse";
import { Tenant } from "../../model/Tenant";

interface AuthContextInterface {
    userInfo: UserResponse | null,
    tenant: Tenant | null,
    isAuthenticated: boolean,
    setNeedToReload: (needToReload: boolean) => void
};

export const AuthContext = createContext<AuthContextInterface>({
    userInfo: null,
    tenant: null,
    isAuthenticated: false,
    setNeedToReload: () => { }
});