import React from "react";
import './PollRunningFooter.css';
import { WorkflowStateContext } from "../../../providers/context/WorkflowStateContext";
import pollIcon from '../../../assets/vote.svg';
import spinner from '../../../assets/spinner.svg';

const PollRunningFooter = () => {
    const { countVoters, countTotalVoters } = React.useContext(WorkflowStateContext);

    const voters = countVoters();
    const totalVoters = countTotalVoters();
    const votingPercentage = totalVoters === 0 ? 0 : (voters / totalVoters) * 100;

    return (
        <div className='poll-running'>
            <div className="poll-icon-container">
                <img
                    className='spinner'
                    src={spinner}
                    alt='spinner'>
                </img>
                <div className="avatar-container" >
                    <img className='avatar'
                        src={pollIcon}
                        alt='poll-icon' >
                    </img>
                </div>
            </div>
            <div className='poll-result-container'>
                <div className='poll-result-text-container'>
                    <h2>
                        Votazione in corso...
                    </h2>
                    <span>
                        {voters}/{totalVoters}
                    </span>
                </div>
                <div className='poll-result-bar-container'>
                    <div
                        style={{
                            width: `${votingPercentage}%`
                        }}>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default PollRunningFooter;