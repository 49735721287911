import './App.css';
import WorkflowStateProvider from "./providers/WorkflowStateProvider";
import CaptionPage from "./pages/CaptionPage";
import AuthContextProvider from './providers/AuthContextProvider';

function App() {
  return (
    <div className="App">
      <AuthContextProvider>
        <WorkflowStateProvider>
          <CaptionPage />
        </WorkflowStateProvider>
      </AuthContextProvider>
    </div>
  );
}

export default App;
