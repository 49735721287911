import './PollEndedFooter.css'
import pollIcon from '../../../assets/vote.svg';
import React from 'react';
import { WorkflowStateContext } from '../../../providers/context/WorkflowStateContext';


const PollEndedFooter = () => {
    const { workflowState } = React.useContext(WorkflowStateContext);


    const outcomeId = workflowState?.currentOutcomeId;
    const pollType = workflowState?.pollTypes?.find(p => p.pollTypeId === workflowState.currentPollType);
    const pollOutcome = pollType?.pollOutcomes.find(o => o.id === outcomeId);

    return (
        <div className='poll-ended'>
            <div className="poll-icon-container">
                <div className="avatar-container" >
                    <img className='avatar'
                        src={pollIcon}
                        alt='poll-icon' >
                    </img>
                </div>
            </div>
            {pollOutcome &&
                <div className='poll-result-container'>
                    <div className='poll-result-text-container'>
                        <div className='poll-result-state'>
                            <h2>
                                Votazione conclusa
                            </h2>
                        </div>
                        <div className='poll-result'>
                            <h2>
                                Esito
                            </h2>
                            <h2 className='result' style={{ backgroundColor: pollOutcome?.color }}>
                                {pollOutcome?.text}
                            </h2>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}


export default PollEndedFooter;