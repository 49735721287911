import React from "react";
import { WorkflowStateContext } from "./context/WorkflowStateContext";
import { AuthContext } from "./context/AuthContext";
import useWorkflowState from "../hooks/useWorkflowState";
import { WorkflowStateDescription } from "../model/WorkflowState";
type WorkflowStateProviderProps = {
  children: any;
};

const WorkflowStateProvider = ({ children }: WorkflowStateProviderProps) => {
  const { userInfo } = React.useContext(AuthContext);

  const workflowState = useWorkflowState({ userId: userInfo!.id, tentaId: userInfo!.tenantId });

  const getFirstSpeakingParticipant = React.useCallback(() => workflowState?.participants.find(el => workflowState?.speechUserStatuses.filter(el => el.hasFloor)[0]?.id === el.id), [workflowState]);
  const getFirstQueuedParticipant = React.useCallback(() => workflowState?.participants.find(el => workflowState?.floorReservationsQueue[0] === el.id), [workflowState]);
  const getParticipant = React.useCallback((participantId: string) => workflowState?.participants.find(el => participantId === el.id), [workflowState]);
  const getParticipantGroup = React.useCallback((groupId: string) => workflowState?.participantGroups.find(pg => pg.id === groupId), [workflowState]);
  const getRunningAgendaItem = React.useCallback(() => workflowState?.agenda?.items.find(item => item.id === workflowState.agendaItemDiscussion?.agendaItem), [workflowState]);
  const getPollEntries = React.useCallback(() => workflowState?.pollTypes?.find(p => p.pollTypeId === workflowState.currentPollType)?.pollEntries ?? [], [workflowState]);
  const countAttendants = React.useCallback(() => workflowState?.participants?.filter(participant => participant.isContributingToCallQuorum && participant.isAttending === true)?.length ?? 0, [workflowState]);
  const countPaticipants = React.useCallback(() => workflowState?.participants?.filter(participant => participant.canAttend)?.length ?? 0, [workflowState]);
  const countVoters = React.useCallback(() => workflowState?.participants?.filter(p => p.currentVote)?.length ?? 0, [workflowState]);
  const countTotalVoters = React.useCallback(() => workflowState?.participants?.filter(p => p.canVote || p.currentVote)?.length ?? 0, [workflowState]);
  const getPollResults = React.useCallback(() => workflowState?.currentPollResult ?? [], [workflowState]);

  const isInPoll = workflowState?.currentState === WorkflowStateDescription.POLL ||
    workflowState?.currentState === WorkflowStateDescription.EXECUTABILITY_POLL ||
    workflowState?.currentState === WorkflowStateDescription.STOPPED_POLL;

  return (
    <WorkflowStateContext.Provider value={{
      workflowState,
      isInPoll,
      currentState: workflowState?.currentState,
      getPollEntries,
      countAttendants,
      countPaticipants,
      countVoters,
      countTotalVoters,
      getPollResults,
      getFirstSpeakingParticipant,
      getFirstQueuedParticipant,
      getParticipant,
      getParticipantGroup,
      getRunningAgendaItem
    }}>
      {children}
    </WorkflowStateContext.Provider>
  );
}

export default WorkflowStateProvider;
