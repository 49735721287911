import { createContext } from "react";
import { CurrentPollResult, Group, Item, Participant, PollEntry, WorkflowState, WorkflowStateDescription } from "../../model/WorkflowState";
import { SlideInOutChild } from "../../pages/components/SlideInOut/SlideInOut";

type WorkflowStateContextType = {
    workflowState: WorkflowState | undefined,
    getFirstQueuedParticipant: () => Participant | undefined,
    getFirstSpeakingParticipant: () => Participant | undefined,
    getParticipant: (participantId: string) => Participant | undefined,
    getParticipantGroup: (groupId: string) => Group | undefined,
    getRunningAgendaItem: () => Item | undefined,
    getPollEntries: () => PollEntry[],
    countAttendants: () => number,
    countPaticipants: () => number,
    countVoters: () => number,
    countTotalVoters: () => number,
    getPollResults: () => CurrentPollResult[],
    isInPoll: boolean,
    currentState: WorkflowStateDescription | undefined,
};


export const WorkflowStateContext = createContext<WorkflowStateContextType>({
    workflowState: undefined,
    getFirstSpeakingParticipant: () => undefined,
    getFirstQueuedParticipant: () => undefined,
    getParticipant: (_) => undefined,
    getParticipantGroup: (_) => undefined,
    getRunningAgendaItem: () => undefined,
    countAttendants: () => 0,
    countPaticipants: () => 0,
    countTotalVoters: () => 0,
    countVoters: () => 0,
    getPollEntries: () => [],
    getPollResults: () => [],
    isInPoll: false,
    currentState: undefined
});