import React from 'react';
import './CaptionPage.css';
import Intervention from './components/Intervention/Intervention';
import { AuthContext } from '../providers/context/AuthContext';
import AgendaItem from './components/AgendaItem/AgendaItem';
import PollRunningFooter from './components/PollRunningFooter/PollRunningFooter';
import { WorkflowStateDescription } from '../model/WorkflowState';
import { WorkflowStateContext } from '../providers/context/WorkflowStateContext';
import PollEndedFooter from './components/PollEndedFooter/PollEndedFooter';
import PollBody from './components/PollBody/PollBody';
import SlideInOut, { SlideInOutChild } from './components/SlideInOut/SlideInOut';

export interface ICaptionPageProps {
}

export default function CaptionPage(props: ICaptionPageProps) {

  const { tenant } = React.useContext(AuthContext)
  const { currentState, isInPoll, getRunningAgendaItem } = React.useContext(WorkflowStateContext);

  const getTop = (): SlideInOutChild | undefined => {
    const agenda = getRunningAgendaItem();
    return agenda ? { id: agenda.id, content: <AgendaItem /> } : undefined
  }

  const getCenter = (): SlideInOutChild | undefined => {
    return isInPoll ? { id: 'PollBody', content: <PollBody /> } : undefined;
  }

  const getBottom = (): SlideInOutChild | undefined => {
    let bottom: SlideInOutChild | undefined;

    if ((currentState === WorkflowStateDescription.POLL || currentState === WorkflowStateDescription.EXECUTABILITY_POLL)) {
      bottom = {
        id: 'PollRunning',
        content: <PollRunningFooter />
      }
    }

    if (currentState === WorkflowStateDescription.STOPPED_POLL) {
      bottom = {
        id: 'PollEnded',
        content: <PollEndedFooter />
      }
    }

    if (!isInPoll) {
      bottom = {
        id: `Intervention`,
        content: <Intervention />
      }
    }

    return bottom;
  }

  return (
    <div className="caption-page-wrapper">
      <div className={`caption-page ${isInPoll ? 'poll' : ''}`}>
        <div className="top">
          <div className='content'>
            <SlideInOut>
              {getTop()}
            </SlideInOut>
          </div>
          <div className='logo'>
            <img style={{ width: '100%' }} src={tenant?.logo} alt={tenant?.name}>
            </img>
          </div>
        </div>
        <div className='center'>
          <div style={{ height: '100%' }}>
            <SlideInOut>
              {getCenter()}
            </SlideInOut>
          </div>
        </div>
        <div className="bottom">
          <SlideInOut>
            {getBottom()}
          </SlideInOut>
        </div>
      </div>
    </div>
  );
}
