import { useContext, useEffect, useRef, useState } from "react";
import { v4 as uuid } from 'uuid';
import { MQTTProvider, WebsocketResponseMessage } from "../utilities/MQTT/MQTTProvider";
import { WorkflowState } from "../model/WorkflowState";
import { AuthContext } from "../providers/context/AuthContext";

interface useWorkflowStateProps {
  userId: string,
  tentaId: string
};

interface WebsocketUserSateResponseMessage extends WebsocketResponseMessage {
  executingWorkflows: { workflowId: string }[],
};

interface WebsocketWorkflowSateResponseMessage extends WebsocketResponseMessage {
  state: WorkflowState,
};

const useWorkflowState = ({ userId, tentaId }: useWorkflowStateProps) => {
  const baseWsUrl = process.env.REACT_APP_WORKFLOW_WSS_URL!;
  const { setNeedToReload } = useContext(AuthContext);

  const [workflowState, setWorkflowState] = useState<WorkflowState | undefined>();
  let subscribedWorkflowId: string | undefined;
  const mqttProvider = useRef<MQTTProvider>();

  const generateClientId = () => `user_${userId}_${tentaId}_${uuid()}`;
  const generateWorkflowTopic = (workflowId: string) => `workflows/${workflowId}`;

  const workflowStateHhandler = (message: WebsocketWorkflowSateResponseMessage) => {
    if (message && message.messageType === 'WorkflowState' && message.state) {
      setWorkflowState(message.state);
    }
  };

  const userStateHandler = (message: WebsocketUserSateResponseMessage) => {
    if (message && message.messageType === 'UserState' && message.executingWorkflows) {
      const { executingWorkflows } = message;
      if (executingWorkflows.length > 0) {
        const id = executingWorkflows[0].workflowId;
        subscribedWorkflowId = id;
        mqttProvider.current?.subscribe(generateWorkflowTopic(subscribedWorkflowId), workflowStateHhandler);
      } else {
        if (subscribedWorkflowId) {
          mqttProvider.current?.unsubscribe(generateWorkflowTopic(subscribedWorkflowId));
          subscribedWorkflowId = undefined;
        }
        setWorkflowState(undefined);
      }
    }
  };

  const handleConnectionRefused = () => {
    setNeedToReload(true);
  };

  useEffect(() => {
    mqttProvider.current = new MQTTProvider({
      onConnectHandler: userStateHandler,
      onConnectionRefused: handleConnectionRefused,
      url: `${baseWsUrl}`,
      mqttOptions: {
        protocolVersion: 5,
        clean: false,
        clientId: generateClientId(),
        transformWsUrl: (url, options, client) => {
          client.options.clientId = generateClientId()
          return url;
        }
      },
    });
    return () => {
      if (mqttProvider.current) {
        mqttProvider.current.dispose();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return workflowState;
};

export default useWorkflowState;
