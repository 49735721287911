import './PollBody.css'
import React from 'react';
import { WorkflowStateContext } from '../../../providers/context/WorkflowStateContext';
import { Participant, PollEntry } from '../../../model/WorkflowState';
import ScrollingText from '../ScrollingText/ScrollingText';
import VotersAvatars from '../VotersAvatars/VotersAvatars';


const PollBody = () => {
    const {
        getPollEntries,
        countAttendants,
        countPaticipants,
        countVoters,
        countTotalVoters,
        getPollResults,
        getParticipant,
    } = React.useContext(WorkflowStateContext);

    const pollEntries = getPollEntries();

    const attendants = countAttendants();
    const participants = countPaticipants();

    const voters = countVoters();
    const totalVoters = countTotalVoters();

    const results = getPollResults();

    const buildPollEntryRow = (pollEntry: PollEntry) => {
        const curretPollResult = results.find(r => r.id === pollEntry.id);
        const pollResultValue = curretPollResult?.value ?? 0;
        const pollEntryPercentage = (totalVoters == 0 ? 0 : pollResultValue / totalVoters) * 100;
        const voters: Participant[] = (curretPollResult?.userIds.map(id => getParticipant(id)) ?? []).filter((participant): participant is Participant => participant !== null);
        const pollColor = !pollEntry.color || pollEntry.color === '' ? 'white' : pollEntry.color;

        return (
            <>
                <tr className='poll-result-row'>
                    <td className='poll-result-options'>
                        <div className='poll-result-option-container'>
                            <span className='poll-result-option-name'>
                                {pollEntry.id}
                            </span>
                            <span className='poll-result-option-value'>
                                {pollResultValue}
                            </span>
                        </div>
                        <div className='poll-result-option-bar'
                            style={{
                                backgroundColor: pollColor,
                                width: `${pollEntryPercentage}%`
                            }}>
                        </div>
                    </td>
                    <td>
                        <VotersAvatars voters={voters} borderColor={pollColor} />
                    </td>
                </tr>
            </>
        );
    }

    const table = <table style={{ width: '100%' }}>
        {pollEntries.map(buildPollEntryRow)}
    </table>;

    return (
        <div className='poll-body'>
            <div className='poll-voters'>
                <div>
                    Presenti: {attendants}/{participants}
                </div>
                <div>
                    Votanti: {voters}/{totalVoters}
                </div>
            </div>
            <div className='poll-results'>
                <ScrollingText direction='vertical' initialDelay={2500} element={table}
                />
            </div>
        </div>
    )
}


export default PollBody;