import React from "react";
import axios from "axios";
import { Tenant } from "../model/Tenant";


const useTenantAPI = (hostName: string | null, needToReload: boolean): Tenant | null => {
    const baseUrl = process.env.REACT_APP_REST_API_PHP_URL!;

    const instance = React.useMemo(
        () =>
            axios.create({
                baseURL: baseUrl,
                headers: {

                }
            }),
        [baseUrl]
    );

    const [data, setData] = React.useState<Tenant | null>(null);

    const load = async () => {
        try {
            const resposne = await instance.get<Tenant>(`/tenants/${hostName}/basic-info`);
            if (resposne?.data)
                setData(resposne.data);
        } catch (error: any) {
            setData(null);
        }
    }

    React.useEffect(() => {
        if (hostName && needToReload)
            load();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hostName, needToReload])

    return data;
}

export default useTenantAPI;