import React from "react";
import useRestApiV2Service from "../services/useRestApiV2Service";

const useCookieApi = (apiKey: string | null, hostName: string | null, needToReload: boolean): boolean => {
    let expirationTimeout: NodeJS.Timeout
    const { loginWithApiKey } = useRestApiV2Service();

    const [data, setData] = React.useState<boolean | null>(null);

    const load = async () => {
        try {
            const response = await loginWithApiKey(hostName!, apiKey!);
            if (response) {
                setData(true);
                const millisecondsUntilTokenExpirations = ((new Date(response.expiration).getTime()) - (new Date()).getTime());
                //We try to refresh the token 10 seconds before the expiration to avoid cations flickering 
                const millisecondsUntilTokenRefresh = millisecondsUntilTokenExpirations - 10000;
                expirationTimeout = setTimeout(load, millisecondsUntilTokenRefresh);
            }
        } catch {
            setData(false);
        }
    }

    React.useEffect(() => {
        if (hostName && apiKey && needToReload)
            load();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hostName, apiKey, needToReload])

    React.useEffect(() => {
        return () => {
            if (expirationTimeout)
                clearTimeout(expirationTimeout);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return data ?? false;
}

export default useCookieApi;