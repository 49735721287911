import React from "react";
import { AuthContext } from "./context/AuthContext";
import useTenantAPI from "../hooks/useTenantsApi";
import useCookieApi from "../hooks/useCookieApi";
import useUserApi from "../hooks/useUserApi";


interface AuthContextProviderProps {
  children: JSX.Element
}

const AuthContextProvider = ({ children }: AuthContextProviderProps) => {
  const queryParameters = new URLSearchParams(window.location.search)
  const apiKey = queryParameters.get('apiKey');
  const tenantName = window.location?.hostname;

  const [needToReload, setNeedToReload] = React.useState(true);

  const isAuthenticated = useCookieApi(apiKey, tenantName, needToReload);
  const tenant = useTenantAPI(tenantName, isAuthenticated);
  const userInfo = useUserApi(isAuthenticated);

  React.useEffect(() => {
    if (isAuthenticated)
      setNeedToReload(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated])

  return (
    <AuthContext.Provider value={{
      tenant,
      userInfo,
      isAuthenticated,
      setNeedToReload
    }}>
      {(isAuthenticated && userInfo && tenant) && children}
    </AuthContext.Provider>
  );

}


export default AuthContextProvider;