import React from "react";
import { UserResponse } from "../services/models/UserResponse";
import useRestApiV2Service from "../services/useRestApiV2Service";


const useUserApi = (needToReload: boolean): UserResponse | null => {
    const { getUserInfo } = useRestApiV2Service();

    const [data, setData] = React.useState<UserResponse | null>(null);

    const load = async () => {
        try {
            const response = await getUserInfo();
            if (response) {
                setData(response);
            } else {
                setData(null);
            }
        } catch {
            setData(null);
        }
    }

    React.useEffect(() => {
        if (needToReload)
            load();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [needToReload])

    return data;
}

export default useUserApi;