import mqtt from "precompiled-mqtt";
import { Inflate } from "pako";


export interface WebsocketResponseMessage {
  messageType: string,
};

interface MQTTProviderProps {
  url: string,
  mqttOptions?: mqtt.IClientOptions,
  onConnectHandler: (payload: any) => void
  onConnectionRefused: () => void
}

export class MQTTProvider {
  mqttClient: mqtt.MqttClient;
  onConnectHandler: (payload: any) => void;
  onConnectionRefused: () => void;
  handlers: { [id: string]: Function } = {};

  constructor({ url, mqttOptions, onConnectHandler, onConnectionRefused }: MQTTProviderProps) {
    this.onConnectHandler = onConnectHandler;
    this.onConnectionRefused = onConnectionRefused;
    this.mqttClient = mqtt.connect(url, mqttOptions);
    this.mqttClient.on("error", this.onError);
    this.mqttClient.on("connect", this.onConnect);
    this.mqttClient.on("message", this.onMessage);
    this.mqttClient.on("offline", console.log);
    this.mqttClient.on("end", () => console.log('end'));
  }

  public subscribe = (topic: string, handleMessage: (payload: any) => void) => {
    this.handlers[topic] = handleMessage;
    this.mqttClient.subscribe(topic, { qos: 1 }, console.warn);
  }

  public unsubscribe = (topic: string) => {
    delete this.handlers[topic];
    this.mqttClient.unsubscribe(topic, console.warn);
  }

  private onConnect: mqtt.OnConnectCallback = (packet) => {
    let subscriptionTopic: string | undefined;
    const authData = packet.properties?.authenticationData?.toString();
    if (authData) {
      const { userTopicName } = JSON.parse(authData);
      subscriptionTopic = userTopicName;
    }
    if (subscriptionTopic) {
      this.subscribe(subscriptionTopic, this.onConnectHandler);
    }
  }

  private onMessage: mqtt.OnMessageCallback = (topic: string, payload: Buffer) => {
    let result;
    if (payload.length < 2 || payload[0] !== 0x1f || payload[1] !== 0x8b) {
      result = payload.toString();
    } else {
      const inflator = new Inflate({ to: 'string' });
      inflator.push(payload);

      if (inflator.err) {
        throw new Error('Could not decompress message');
      }
      result = inflator.result.toString()
    }
    if (this.handlers.hasOwnProperty(topic)) {
      this.handlers[topic](JSON.parse(result));
    }
  };

  private onError: mqtt.OnErrorCallback = (error) => {
    if (error.message.startsWith('Connection refused')) {
      this.onConnectionRefused();
    }
  }

  dispose = () => {
    if (this.mqttClient) {
      this.mqttClient.end();
    }
  }
}
