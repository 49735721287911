
export enum WorkflowStateDescription {
  EXIT = 'ExitCouncil', // Only frontend!!
  CALL = 'Call',
  END = 'End',
  PAUSE = 'Pause',
  INVALID_QUORUM = 'InvalidQuorum',
  VALID_QUORUM = 'ValidQuorum',
  START = 'Start',
  EXPLANATION_OF_VOTE = 'ExplanationOfVote',
  POLL = 'Poll',
  EXECUTABILITY_POLL = 'ExecutabilityPoll',
  CONFIGURE_EXECUTABILITY_POLL = 'ConfigureExecutabilityPoll',
  STOPPED_POLL = 'StoppedPoll',
  AGENDA_ITEM_DISCUSSION = 'AgendaItemDiscussion'
}

export interface WorkflowState {
  currentOutcomeId?: string;
  currentPollType?: string;
  pollTypes?: PollType[]
  currentState?: WorkflowStateDescription;
  speechUserStatuses: SpeechUserStatus[];
  participants: Participant[];
  floorReservationsQueue: string[];
  participantGroups: Group[];
  agendaItemDiscussion?: AgendaItemDiscussion;
  agenda?: Agenda;
  currentPollResult?: CurrentPollResult[]
}

export interface CurrentPollResult {
  id: string
  uuid: string
  value: number
  userIds: string[]
}


export interface PollType {
  id: string
  pollTypeId: string
  name: string
  type: string
  duration: number
  pollEntries: PollEntry[]
  pollOutcomes: PollOutcome[]
  quorumDescription?: string
}

export interface PollEntry {
  id: string
  text: string
  color?: string
}

export interface PollOutcome {
  id: string
  text: string
  color: string
  isConclusive: boolean
  isDefault: boolean
  quorumDescription: string
}


export interface Agenda {
  items: Item[]
}

export interface Item {
  id: string
  ordinal: string
  type: number
  title: string
  active: boolean
  order: number
  startTime?: string
  stopTime: any
  attachments: any[]
  items: Item[]
}


export interface AgendaItemDiscussion {
  agendaItemSessions: AgendaItemSession[]
  agendaItem: string
  currentAgendaItem: any
  inProgress: boolean
  initialSpeechStarted: boolean
  remainingOpenedPolls: string[]
  remainingExplanationsOfPoll: string[]
  remainingPlannedPolls: string[]
  canUndo: boolean
  isMergedAgendaItem: boolean
}

export interface AgendaItemSession {
  id: string
  explanationOfPollStarted: boolean
  pollStarted: boolean
  pollCompleted: boolean
}



export interface Group {
  id: string
  name: string
  image: string | undefined
  order: number
}

export interface SpeechUserStatus {
  elapsedInSeconds: number;
  elapsedLastSegmentInSeconds: number;
  hasFloor: boolean;
  id: string;
  interventionsCounter: number;
  isModerating: boolean;
  isPaused: boolean;
  remainingInSeconds: number;
  updated: string;
}

export interface Participant {
  canAttend: boolean;
  canBecomePresident: boolean;
  canBecomeSecretary: boolean;
  canDownloadReports: boolean;
  canPresent: boolean;
  canReserveFloor: boolean;
  canVote: boolean;
  conferenceSystemName: string | null;
  currentVote: string;
  firstName: string;
  groupDescription: string;
  groupId: string;
  hasCameraBlocked: boolean;
  hasCameraOn: boolean;
  id: string;
  image: string;
  isAssistant: boolean;
  isAttending: boolean;
  isContributingToCallQuorum: boolean;
  isEligibleVoter: boolean;
  isInCouncil: boolean;
  isManager: boolean;
  isOnline: boolean;
  isPresident: boolean;
  isSecretary: boolean;
  isTeller: boolean;
  isTemporaryAssistant: boolean;
  isTemporaryManager: boolean;
  isTemporaryNotVoter: boolean;
  isVoter: boolean;
  lastName: string;
  order: number;
  originalUserRole: number;
  roleDescription: string;
  roleId: string;
  roleOrder: number;
}
