import React from 'react';
import './SlideInOut.css'
import { CSSTransition } from 'react-transition-group';


export interface SlideInOutChild {
    id: string;
    content: JSX.Element
}

interface SlideInOutProps {
    children: SlideInOutChild | undefined;
}

const SlideInOut = ({ children }: SlideInOutProps) => {
    const [actual, setActual] = React.useState<SlideInOutChild>();
    const [show, setShow] = React.useState(true);
    const nodeRef = React.useRef(null);

    React.useEffect(() => {
        if (children?.id === actual?.id) return;
        setShow(false);
    }, [children]);

    return (
        <CSSTransition
            in={show}
            nodeRef={nodeRef}
            timeout={ show ? 1000 : 300}
            classNames="slider"
            mountOnEnter
            unmountOnExit
            onEnter={() => {
                setActual(children);
            }}
            onExited={() => {
                setShow(true);
            }}
        >
            <div ref={nodeRef} className='slider-wrapper' >
                {actual?.content}
            </div>
        </CSSTransition>
    );
}

export default SlideInOut;